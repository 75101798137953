import React, { lazy, Suspense, useEffect } from 'react';
import { useWidget, styled } from '@guest-widgets/core';
import { classes } from '@guest-widgets/shared/src/classes';
import { Loadable } from '@guest-widgets/shared/src/utils/loadable/loadable';
import { ErrorViews, ErrorState } from '@guest-widgets/shared/src/components/ErrorViews';
import {
  DetailGiftCardProductDto,
  DetailProductDto,
} from '@checkfront/guest-experience-api-api-client-javascript-axios';
import { useQueryRouter } from '@guest-widgets/shared/src/components/Router/QueryRouter';
import { config } from '@guest-widgets/shared/src/config';
import { useApplication } from '@guest-widgets/shared/src/contexts/appplicationContext/applicationContext';

import { useProduct } from '../productContext';
import { useSettings } from '../SettingsContext/settingsContext';
import {
  ProductDetailsSkeleton,
  TripAdvisorSkeleton,
} from '../ProductDetailsSkeleton/ProductDetailsSkeleton';
import { BookingSkeleton } from '../ProductDetailsSkeleton/BookingSkeleton';
import { CrossSellingSkeleton } from '../ProductDetailsSkeleton/CrossSellingSkeleton';
import { ReviewsSkeleton } from '../ProductDetailsSkeleton/ReviewsSkeleton';

import { GoogleStructuredData } from './GoogleStructuredData';
import { Location } from './Location/Location';
import { ProductHead } from './ProductHead/ProductHead';
import { Information } from './Information/Information';
import { Description } from './Description';
import { Return } from './Return';

const Booking = lazy(() => import('./Booking'));
const Reviews = lazy(() => import('./Reviews/Reviews'));
const Tripadvisor = lazy(() => import('./Tripadvisor/Tripadvisor'));
const CrossSelling = lazy(() => import('./CrossSelling'));

interface ProductDetailsProps {
  onBack?(): void;
  bookingComponent?: React.FC;
}

export const ProductDetails = ({
  onBack,
  bookingComponent: givenBookingComponent,
}: ProductDetailsProps) => {
  const { product } = useProduct();
  const { widgetArea, containerElement } = useWidget();
  const { productId } = useSettings();
  const { get } = useQueryRouter();
  const { productIdQueryKey } = config;
  const { refresh } = useApplication();

  const BookingComponent: React.FC = givenBookingComponent ?? Booking;

  useEffect(() => {
    if (get(productIdQueryKey) === productId)
      containerElement.scrollIntoView({ behavior: 'smooth' });
  }, [product.value]);

  const reviews = (
    <Suspense fallback={<ReviewsSkeleton />}>
      <Reviews />
    </Suspense>
  );

  const crossSelling = (
    <Suspense fallback={<CrossSellingSkeleton />}>
      <CrossSelling />
    </Suspense>
  );

  const tripadvisor = (product: Loadable<DetailProductDto | DetailGiftCardProductDto>) => (
    <Suspense fallback={<TripAdvisorSkeleton />}>
      <Tripadvisor data={product.value.operator?.tripadvisor} />
    </Suspense>
  );

  if (product.isLoading || product.isEmpty)
    return <ProductDetailsSkeleton hasBackButton={!!onBack} />;

  if (product.isFailed)
    return (
      <Container className={classes.details.root}>
        <ErrorViews
          type={
            product.error.response?.status === 404 ? ErrorState.NotFound : ErrorState.UnableToLoad
          }
          onClick={product.error.response?.status === 404 ? onBack : () => refresh()}
        />
      </Container>
    );

  return (
    <Container className={classes.details.root}>
      <GoogleStructuredData />

      {onBack && <Return onBack={onBack} />}

      <Content className={classes.details.content.root}>
        <Main className={classes.details.content.main}>
          <ProductHead />
          <Description />
          <Information />
          <Location />

          {!widgetArea.isMedium && reviews}
          {widgetArea.isSmall && (
            <>
              {tripadvisor(product)}

              {crossSelling}
            </>
          )}
        </Main>
        <Aside className={classes.details.content.side}>
          <Suspense fallback={<BookingSkeleton />}>
            <BookingComponent />
          </Suspense>
          {!widgetArea.isSmall && tripadvisor(product)}
        </Aside>
      </Content>
      {widgetArea.isMedium && reviews}
      {!widgetArea.isSmall && crossSelling}
    </Container>
  );
};

export const Container = styled('div')(({ theme, widgetArea }) => ({
  padding: theme.spacing(widgetArea.basedOnSize(4, 6, 8)),
  backgroundColor: theme.palette.background.paper,
}));

export const Content = styled('div')(({ widgetArea }) => ({
  display: widgetArea.isSmall ? 'block' : 'flex',
}));

export const Main = styled('div')(({ theme, widgetArea }) => ({
  width: widgetArea.basedOnSize('100%', '55%', '66%'),
  maxWidth: widgetArea.basedOnSize('100%', 'calc(100% - 320px)'),
  paddingRight: theme.spacing(widgetArea.basedOnSize(0, 6, 8)),
  paddingBottom: theme.spacing(widgetArea.basedOnSize(4, 0)),
}));

export const Aside = styled('div')(({ theme, widgetArea }) => ({
  width: widgetArea.basedOnSize(undefined, '45%', '34%'),
  minWidth: 320,
  position: 'sticky',
  bottom: 0,
  padding: theme.spacing(widgetArea.basedOnSize(4, 0)),
  margin: theme.spacing(widgetArea.basedOnSize(-4, 0)),
  paddingTop: 0,
  marginTop: 0,
  zIndex: 1,
}));
