import React, { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { shallowEqual } from '@guest-widgets/shared/src/utils/shallowEqual';
import { useQueryRouter } from '@guest-widgets/shared/src/components/Router/QueryRouter';
import { useFeature } from '@guest-widgets/shared/src/contexts/featureContext/featureContext';
import { Feature } from '@guest-widgets/shared/src/features';
import dayjs from 'dayjs';

import type { DateRange, Filters, Location, PriceRange, Sort } from '../filters';
import { dateRangesAreEqual, locationsAreEqual, priceRangesAreEqual } from '../filters';
import { useFeatureToggleFilters } from '../useFeatureToggleFilters';

import { mapToFilters, mapToSearchParams } from './searchParamsMappers';

interface FiltersProviderProps {
  initialState?: Filters;
}

type SetFilter = <K extends keyof Filters>(key: K) => (param: Filters[K]) => void;

export interface FiltersContext {
  filters: Filters;
  numberOfFiltersApplied: number;
  setFilter: SetFilter;
  resetFilters(): void;
  setDates(dates?: DateRange): void;
  setLocation(location?: Location): void;
  setPrice(price?: PriceRange): void;
  setCategories(categories?: string[]): void;
  setMisc(topThingsToDo?: string[]): void;
  setSort(sort?: Sort): void;
  setLanguages(languages?: string[]): void;
  nextDates?: DateRange;
  setNextDates(dates?: DateRange): void;
}

export const defaultFiltersContextValue: FiltersContext = {
  filters: {},
  numberOfFiltersApplied: 0,
  resetFilters: () => {},
  setFilter: () => () => {},
  setDates: () => {},
  setLocation: () => {},
  setPrice: () => {},
  setCategories: () => {},
  setMisc: () => {},
  setSort: () => {},
  setLanguages: () => {},
  setNextDates: () => {},
};

export const filtersContext = createContext<FiltersContext>(defaultFiltersContextValue);

export const FiltersProvider = ({
  children,
  initialState = {},
}: PropsWithChildren<FiltersProviderProps>) => {
  const queryRouter = useQueryRouter();
  const featureToggleFilters = useFeatureToggleFilters();

  const [filters, setFilters] = useState<Filters>(() =>
    mapToFilters(initialState, queryRouter, featureToggleFilters)
  );

  const [nextDates, setNextDates] = useState<DateRange | undefined>(undefined);

  useEffect(() => {
    const newFilters = mapToFilters(initialState, queryRouter, featureToggleFilters);
    if (filtersAreEqual(filters, newFilters)) return;
    setFilters(newFilters);
  }, [queryRouter]);

  useEffect(() => {
    setNextDates(filters.dates);
  }, [filters.dates]);

  const resetFilters = () => {
    applyFilters({});
  };

  const applyFilters = (newFilters?: Filters) => {
    const newAppliedFilters = newFilters || filters;
    setFilters(newAppliedFilters);
    queryRouter.setMultiple(...mapToSearchParams(newAppliedFilters, initialState));
  };

  const setFilter = <K extends keyof Filters>(key: K) => (param: Filters[K]) => {
    const nextFilters = { ...filters, [key]: param };
    if (param === undefined) delete nextFilters[key];
    applyFilters(nextFilters);
  };

  const value: FiltersContext = {
    filters,
    numberOfFiltersApplied: Object.keys(filters).length,
    resetFilters,
    setFilter,
    setDates: setFilter('dates'),
    setLocation: setFilter('location'),
    setPrice: setFilter('price'),
    setCategories: setFilter('categories'),
    setMisc: setFilter('misc'),
    setSort: setFilter('sort'),
    setLanguages: setFilter('languages'),
    nextDates,
    setNextDates,
  };

  return <filtersContext.Provider value={value}>{children}</filtersContext.Provider>;
};

export const useFilters = () => useContext(filtersContext);

const filtersAreEqual = (filtersA: Filters, filtersB: Filters) => {
  const keysA = Object.keys(filtersA);
  const keysB = Object.keys(filtersB);

  if (keysA.length !== keysB.length) return false;

  for (let i = 0; i < keysA.length; i++) {
    const key = keysA[i];
    const valueA = (filtersA as any)[key];
    const valueB = (filtersB as any)[key];

    switch (key) {
      case 'dates':
        if (!dateRangesAreEqual(valueA, valueB)) return false;
        break;
      case 'price':
        if (!priceRangesAreEqual(valueA, valueB)) return false;
        break;
      case 'location':
        if (!locationsAreEqual(valueA, valueB)) return false;
        break;
      default:
        if (!shallowEqual((filtersA as any)[key], (filtersB as any)[key])) return false;
        break;
    }
  }

  return true;
};
