import React from 'react';
import { parseJson, splitProp, MuiProvider, getThemeType } from '@guest-widgets/core';
import { classes } from '@guest-widgets/shared/src/classes';
import { ErrorBoundaryWrapper, APMInit } from '@guest-widgets/shared/src/components/ErrorBoundary';

import { App } from './App/App';
import { SettingsContextProps } from './App/SettingsContext/settingsContext';

export interface ProductWidgetWebComponentProps
  extends Omit<
    SettingsContextProps,
    'productId' | 'disableFeatures' | 'enableFeatures' | 'configuration'
  > {
  product?: string;
  disableFeatures?: string;
  enableFeatures?: string;
  configuration?: string;
  dataDisableFont?: string;
}

APMInit();

export const ProductWidgetWebComponent = ({
  product,
  disableFeatures,
  enableFeatures,
  configuration,
  ...rest
}: ProductWidgetWebComponentProps) => {
  const listEnableFeatures = splitProp(enableFeatures);
  const disableDownloadFont = rest.dataDisableFont === 'true';

  return (
    <ErrorBoundaryWrapper>
      <MuiProvider
        classNamePrefix={classes.catalog.prefix}
        themeType={getThemeType(listEnableFeatures)}
      >
        <App
          {...rest}
          disableDownloadFont={disableDownloadFont}
          productId={product}
          disableFeatures={splitProp(disableFeatures)}
          enableFeatures={listEnableFeatures}
          configuration={parseJson(configuration)}
        />
      </MuiProvider>
    </ErrorBoundaryWrapper>
  );
};
