import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Feature } from '@guest-widgets/shared/src/features';
import { GetProductsParams } from '@guest-widgets/shared/src/apis/guestExperience';
import { Loadable } from '@guest-widgets/shared/src/utils/loadable/loadable';
import { productApi } from '@guest-widgets/shared/src/apis/guestExperience';
import { usePrevious } from '@guest-widgets/shared/src/hooks/usePrevious';
import type {
  CompanyDto,
  ListProductDto,
} from '@checkfront/guest-experience-api-api-client-javascript-axios';
import { useFeature } from '@guest-widgets/shared/src/contexts/featureContext/featureContext';
import dayjs from 'dayjs';
import { FindAllSortEnum } from '@checkfront/guest-experience-api-api-client-javascript-axios/api';

import { useSettings } from './settingsContext';
import type { Filters, CategoryCode } from './filters';
import { useFilters } from './filtersContext/filtersContext';
import { useFilterAttributes } from './filterAttributesContext';

const PAGE_SIZE = 12;

export interface ProductsContext {
  products: Loadable<ListProductDto[]>;
  totalCount?: number;
  loadProducts(): void;
  pageSize: number;
  hasMorePages: boolean;
  companyInformation?: CompanyDto;
  productsPerPage?: ListProductDto[];
}

export const defaultProductsContextValue = {
  products: Loadable.createEmpty<ListProductDto[]>(),
  hasMorePages: false,
  pageSize: PAGE_SIZE,
  loadProducts: () => {},
  itemsPerPage: [],
};

const productsContext = createContext<ProductsContext>(defaultProductsContextValue);

export const ProductsProvider = ({ children }: PropsWithChildren<{}>) => {
  const { isEnabled } = useFeature();
  const { customerCode, locale } = useSettings();
  const { filters } = useFilters();
  const { attributes } = useFilterAttributes();
  const [products, setProducts] = useState(Loadable.createEmpty<ListProductDto[]>());
  const [productsPerPage, setProductsPerPage] = useState<ListProductDto[]>([]);

  const [companyInformation, setCompanyInformation] = useState<CompanyDto>();
  const includeInactive = isEnabled(Feature.ShowInactiveProducts);

  const offsetRef = useRef(0);
  const totalCountRef = useRef<undefined | number>(undefined);

  const categoryCode = attributes.value?.categoryCode;
  const previousCategoryCode = usePrevious(categoryCode);

  const loadProducts = async (resetPager: boolean = false) => {
    if (resetPager) {
      offsetRef.current = 0;
      totalCountRef.current = undefined;
    }

    setProducts(({ value }) => Loadable.createLoading(resetPager ? undefined : value));

    try {
      const requestParams = mapToRequestParams(filters, categoryCode, includeInactive);
      const { items, totalItemCount, company } = await productApi.getProducts(
        customerCode,
        locale,
        {
          ...requestParams,
          offset: offsetRef.current,
          limit: PAGE_SIZE,
        }
      );
      setProductsPerPage(items);
      setCompanyInformation(company);

      totalCountRef.current = totalItemCount;

      setProducts(({ value }) => {
        const usedIDs: { [key: string]: boolean } = {};

        const productsWithoutDuplicates = (resetPager ? [] : value).concat(items).filter((item) => {
          if (usedIDs[item.id]) {
            return false;
          }
          usedIDs[item.id] = true;
          return true;
        });

        const productValidList = Loadable.createValid(productsWithoutDuplicates);
        offsetRef.current += PAGE_SIZE;
        return productValidList;
      });
    } catch (e) {
      setProducts(Loadable.createFailed(e));
    }
  };

  const hasMorePages =
    !!totalCountRef.current && products.value && offsetRef.current < totalCountRef.current;

  useEffect(() => {
    if (!categoryCode && filters.categories) {
      // We wait until category code is loaded from filter-attributes
      return;
    }
    if (categoryCode && !previousCategoryCode && !filters.categories) {
      // Don't reload after category code is defined if no category filter is set.
      //  The products should have already been loaded.
      return;
    }

    // TODO we currently requesting product endpoint twice
    loadProducts(true);
  }, [customerCode, locale, filters, categoryCode, includeInactive]);

  const value: ProductsContext = {
    products,
    hasMorePages,
    loadProducts: () => loadProducts(),
    pageSize: PAGE_SIZE,
    totalCount: totalCountRef.current,
    companyInformation: companyInformation,
    productsPerPage,
  };

  return <productsContext.Provider value={value}>{children}</productsContext.Provider>;
};

export const useProducts = () => useContext(productsContext);

type MutableRequestParams = {
  -readonly [K in keyof GetProductsParams]: GetProductsParams[K];
};

const mapToRequestParams = (
  filters: Filters,
  categoryCode?: CategoryCode,
  includeInactive?: boolean
): GetProductsParams => {
  let params: MutableRequestParams = { includeInactive };

  if (filters.dates) {
    const [from, to] = filters.dates;

    // make sure to use maximum time range from 00:00:00 to 23:59:59
    params.dateFrom = dayjs(from).startOf('day').format('YYYY-MM-DDTHH:mm:ss');
    params.dateTo = dayjs(to ?? from)
      .endOf('day')
      .format('YYYY-MM-DDTHH:mm:ss');
  }

  if (filters.location) {
    params.latitude = filters.location.latitude;
    params.longitude = filters.location.longitude;
    params.radius = filters.location.radius * 1000;
  }

  if (filters.price) {
    const [min, max] = filters.price;
    params.minPrice = min.toString();
    params.maxPrice = max.toString();
  }

  if (filters.categories && categoryCode) {
    params[categoryCode] = filters.categories.join(',');
  }

  if (filters.languages) {
    params.languages = filters.languages.join(',');
  }

  if (filters.sort) {
    params.sort = (filters.sort as unknown) as FindAllSortEnum;
  }

  if (filters.misc) {
    params.topThingsToDo = filters.misc.join(',');
  }

  return params;
};
