import { useTranslator } from '@eo-locale/react';

export const useNumberFormatter = (language?: string) => {
  const translator = useTranslator(language);
  const formatPrice = (price: number, currency: string, withFractionDigits: boolean = true) =>
    translator.formatNumber(price, {
      style: 'currency',
      currency: currency,
      ...(withFractionDigits
        ? {}
        : {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }),
    });
  const formatCurrencyCodeToSymbol = (currencyCode: string) => {
    if (!currencyCode) return;
    const formatter = new Intl.NumberFormat(language, {
      style: 'currency',
      currency: currencyCode,
    });

    const formatted = formatter.format(1);
    const symbol = formatted.replace(/[0-9]/g, '').trim();

    return symbol;
  };

  const formatNegativePrice = (
    price: number,
    currency: string,
    withFractionDigits: boolean = true
  ) => {
    return `-${formatPrice(Math.abs(price), currency, withFractionDigits)}`;
  };

  const formatKilometers = (km: number) =>
    translator.formatNumber(km, {
      style: 'unit',
      unit: 'kilometer',
      maximumFractionDigits: 0,
    });

  return {
    formatPrice,
    formatKilometers,
    formatNegativePrice,
    formatCurrencyCodeToSymbol,
  };
};
