import axios from 'axios';

import {
  PageViewData,
  ProductsData,
  UpdateCartData,
  Tag,
  ViewProductData,
  ViewCartData,
  BeginCheckoutData,
  PurchaseData,
} from './customerTrackingCreator';
import { MatomoEventMapper } from './mappers/matomo/matomoEventMapper';
import { CustomMatomoDimension, MatomoEvents, ViewProductPaq } from './mappers/matomo/matomoTypes';

declare global {
  interface Window {
    _paq: any[];
    matomoScriptLoaded: boolean;
  }
}

export class Matomo implements Tag {
  private matomoEventMapper: MatomoEventMapper;
  private static instance: Matomo;
  private scriptLoaded: boolean = false;

  private constructor() {
    this.matomoEventMapper = new MatomoEventMapper();

    const matomoUrl = import.meta.env.VITE_MATOMO_INSTANCE_URL;
    const matomoSiteId = import.meta.env.VITE_MATOMO_SITE_ID ?? 1;
    if (!this.scriptLoaded && !window.matomoScriptLoaded && matomoUrl && matomoSiteId) {
      this.initializePaq();
      this.loadScript(matomoUrl, matomoSiteId);
    }
  }
  private initializePaq() {
    window._paq = window._paq || [];
  }
  private pushEvent<T>(eventData: T[]) {
    window._paq.push(eventData);
  }
  isAvailable() {
    return typeof window !== 'undefined' && !!window._paq;
  }
  private loadScript(matomoUrl: string, siteId: string) {
    if (this.scriptLoaded || window.matomoScriptLoaded) return;

    (() => {
      const scriptUrl = `${matomoUrl}matomo.js`;
      const trackerUrl = `${matomoUrl}matomo.php`;

      this.pushEvent(['setTrackerUrl', trackerUrl]);
      this.pushEvent(['setSiteId', siteId]);
      const d = document,
        g = d.createElement('script'),
        s = d.getElementsByTagName('script')[0];

      g.type = 'text/javascript';
      g.async = true;
      g.defer = true;
      g.src = scriptUrl;
      s?.parentNode?.insertBefore(g, s);
    })();

    this.scriptLoaded = true;
    window.matomoScriptLoaded = true;
  }

  public static getInstance(): Matomo {
    if (!Matomo.instance) {
      Matomo.instance = new Matomo();
    }
    return Matomo.instance;
  }

  async fetchCustomDimensions(): Promise<CustomMatomoDimension[] | undefined> {
    const url = `https:${import.meta.env.VITE_MATOMO_INSTANCE_URL}index.php`;
    const params = new URLSearchParams();
    params.append('module', 'API');
    params.append('method', 'CustomDimensions.getConfiguredCustomDimensions');
    params.append('idSite', import.meta.env.VITE_MATOMO_SITE_ID);
    params.append('format', 'JSON');
    params.append('token_auth', import.meta.env.VITE_MATOMO_AUTH_TOKEN);

    try {
      const response = await axios.post(url, params, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching custom dimensions:', error);
    }
  }

  setDimensions(dimensions: CustomMatomoDimension[], data: ViewProductPaq) {
    dimensions?.forEach((dimension) => {
      this.pushEvent([MatomoEvents.setCustomDimension, dimension.idsite, data[dimension.name]]);
    });
  }
  removeDimensions(dimensions: CustomMatomoDimension[]) {
    dimensions.forEach((dimension) => {
      this.pushEvent([MatomoEvents.deleteCustomDimension, dimension.idsite]);
    });
  }

  async manageProductEvents(mappedData: ViewProductPaq, action: string) {
    const dimensions = await this.fetchCustomDimensions();

    if (Array.isArray(dimensions) && dimensions.length) {
      this.setDimensions(dimensions, mappedData);
    }

    this.pushEvent([
      MatomoEvents.trackEvent,
      'Ecommerce',
      action,
      mappedData.name,
      mappedData.price,
    ]);
    // Clear custom dimensions after tracking the event to ensure they are not carried over
    if (Array.isArray(dimensions) && dimensions.length) {
      this.removeDimensions(dimensions);
    }
  }
  pageView({ title, virtualPageViewData }: PageViewData): void {
    const data = this.matomoEventMapper.mapPageView({ title, virtualPageViewData });
    this.pushEvent([MatomoEvents.deleteCustomVariables, 'page']);
    this.pushEvent([MatomoEvents.setCustomUrl, data.page_location]);
    this.pushEvent([MatomoEvents.setDocumentTitle, data.page_title]);
    this.pushEvent([MatomoEvents.setReferrerUrl, data.page_referrer]);
    this.pushEvent([MatomoEvents.trackPageView]);
  }

  viewProduct(data: ViewProductData) {
    const mappedData = this.matomoEventMapper.mapToViewProduct(data);
    this.pushEvent(['setEcommerceView', mappedData.name, mappedData.category, mappedData.price]);

    // this is mandatory for tracking ecommerce view
    this.pushEvent(['trackPageView']);
  }

  addToCart(addToCartData: UpdateCartData) {
    const mappedData = this.matomoEventMapper.mapToUpdateCart(addToCartData);
    this.pushEvent([
      MatomoEvents.addEcommerceItem,
      mappedData.productSKU,
      mappedData.name,
      mappedData.category,
      mappedData.price,
      mappedData.quantity ?? 1,
    ]);

    this.pushEvent([MatomoEvents.trackEcommerceCartUpdate, addToCartData.totalAmount ?? 0]);
  }

  removeFromCart(removeFromCartData: UpdateCartData) {
    const mappedData = this.matomoEventMapper.mapToUpdateCart(removeFromCartData);
    this.pushEvent([MatomoEvents.removeEcommerceItem, mappedData.productSKU]);
    this.pushEvent([MatomoEvents.trackEcommerceCartUpdate, removeFromCartData.totalAmount ?? 0]);
  }
  // custom events
  viewProductList(viewProductListData: ProductsData) {
    const mappedData = this.matomoEventMapper.mapToViewProductList(
      viewProductListData.products,
      viewProductListData.listId
    );

    const inputString = (viewProductListData.listId ?? '').split('Categories:')[1]?.trim();
    this.pushEvent([
      MatomoEvents.trackEvent,
      'Ecommerce',
      'viewProductList',
      inputString ?? 'all',
      mappedData.items.length,
    ]);
  }
  viewCart(viewCartData: ViewCartData) {
    const mappedData = this.matomoEventMapper.mapToViewCart(viewCartData);
    this.pushEvent([
      MatomoEvents.trackEvent,
      'Ecommerce',
      'viewCart',
      `items-${mappedData.items.length}`,
      `${mappedData.value}-${mappedData.currency}`,
    ]);
  }
  selectProduct(selectProductData: ProductsData) {
    const mappedData = this.matomoEventMapper.mapToSelectProduct(selectProductData);
    this.manageProductEvents(mappedData, 'selectItem');
  }

  checkout(checkoutData: BeginCheckoutData) {
    const beginCheckoutEventData = this.matomoEventMapper.mapBeginCheckout(checkoutData);

    this.pushEvent([
      MatomoEvents.trackEvent,
      'Ecommerce',
      'beginCheckout',
      'total Price',
      beginCheckoutEventData.totalPrice,
    ]);
  }

  purchase(purchaseData: PurchaseData) {
    const matomoEventData = this.matomoEventMapper.mapPurchase(purchaseData);
    this.pushEvent([MatomoEvents.trackEcommerceOrder, ...matomoEventData]);
  }
}
