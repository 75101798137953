import React from 'react';
import { QueryRouter } from '@guest-widgets/shared/src/components/Router/QueryRouter';
import { FeatureProvider } from '@guest-widgets/shared/src/contexts/featureContext/featureContext';
import { ConfigurationTheme } from '@guest-widgets/shared/src/components/ConfigurationTheme/ConfigurationTheme';
import { ApplicationContextProvider } from '@guest-widgets/shared/src/contexts/appplicationContext/applicationContext';

import {
  SettingsConsumer,
  SettingsContextProps,
  SettingsProvider,
} from './SettingsContext/settingsContext';
import { ProductDetails } from './ProductDetails/ProductDetails';
import { ReviewsProvider } from './reviewsContext';
import { CrossSellingProvider } from './crossSellingContext';
import { ProductProvider } from './productContext';
import { LocalizationProvider } from './LocalizationProvider';

export interface AppProps extends SettingsContextProps {
  onBack?(): void;
}

export const App = ({ onBack, ...rest }: AppProps) => (
  <ApplicationContextProvider>
    <QueryRouter>
      <SettingsProvider {...rest}>
        <LocalizationProvider>
          <SettingsConsumer>
            {({ disableFeatures, enableFeatures, configuration, disableDownloadFont }) => (
              <FeatureProvider disable={disableFeatures} enable={enableFeatures}>
                <ConfigurationTheme
                  configuration={configuration}
                  disableDownloadFont={disableDownloadFont}
                >
                  <ProductProvider>
                    <ReviewsProvider>
                      <CrossSellingProvider>
                        <ProductDetails onBack={onBack} />
                      </CrossSellingProvider>
                    </ReviewsProvider>
                  </ProductProvider>
                </ConfigurationTheme>
              </FeatureProvider>
            )}
          </SettingsConsumer>
        </LocalizationProvider>
      </SettingsProvider>
    </QueryRouter>
  </ApplicationContextProvider>
);

export default App;
