import { urlSearchParams } from '../utils/urlSearchParams';

/**
 * Temporary solution to retrieve the gift card ID from the URL,
 * as the gift card list has not yet been implemented.
 */
export const useGetGiftCardParamId = () => {
  const getGiftCardId = () => {
    const searchParams = urlSearchParams();
    return searchParams.get('giftcardId');
  };

  return { giftCardId: getGiftCardId() };
};
