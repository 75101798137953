import React, { useEffect, useRef, useState } from 'react';
import Skeleton from '@mui/material/Skeleton';
import { useWidget, styled } from '@guest-widgets/core';
import { BookingSkeleton } from '@guest-widgets/shared/src/components/BookingSkeleton';
import { elementArea, widthToBreakpoint } from '@guest-widgets/core';

import { ProductInformationSkeleton } from './ProductInformationSkeleton';
import { CrossSellingSkeleton } from './CrossSellingSkeleton';
import { ReviewsSkeleton } from './ReviewsSkeleton';
import { ReturnSkeleton } from './ReturnSkeleton';
import { BasePriceSkeleton } from './BasePriceSkeleton';

interface ProductDetailsSkeletonProps {
  hasBackButton?: boolean;
}

export const ProductDetailsSkeleton = ({ hasBackButton }: ProductDetailsSkeletonProps) => {
  const { widgetArea } = useWidget();
  const asideRef = useRef<HTMLDivElement>(null);
  const [numberOfMonths, setNumberOfMonths] = useState(1);

  useEffect(() => {
    if (asideRef.current) {
      const asideWidth = asideRef.current.offsetWidth;
      const breakpoint = widthToBreakpoint(asideWidth);
      setNumberOfMonths(elementArea(breakpoint).basedOnSize(1, 2, 3));
    }
  }, [asideRef.current?.offsetWidth]);

  return (
    <Container>
      {hasBackButton && <ReturnSkeleton />}
      <Content>
        <Main>
          <ProductInformationSkeleton />
          {!widgetArea.isMedium && <ReviewsSkeleton />}
        </Main>
        {widgetArea.isSmall && (
          <>
            <TripAdvisorSkeleton variant="rectangular" width="100%" height={150} />
            <CrossSellingSkeleton />
          </>
        )}
        {!widgetArea.isSmall && (
          <Aside ref={asideRef}>
            <BasePriceSkeleton />
            <BookingSkeleton numberOfMonths={numberOfMonths} />
          </Aside>
        )}
      </Content>
      {widgetArea.isMedium && <ReviewsSkeleton />}
      {!widgetArea.isSmall && <CrossSellingSkeleton />}
    </Container>
  );
};

export const TripAdvisorSkeleton = styled(Skeleton)(({ theme }) => ({
  marginTop: theme.spacing(8),
}));

export const Container = styled('div')(({ theme, widgetArea }) => ({
  padding: theme.spacing(widgetArea.basedOnSize(4, 6, 8)),
  backgroundColor: theme.palette.background.paper,
}));

export const Content = styled('div')(({ widgetArea }) => ({
  display: widgetArea.isSmall ? 'block' : 'flex',
}));

export const Main = styled('div')(({ theme, widgetArea }) => ({
  width: widgetArea.basedOnSize('100%', '55%', '66%'),
  maxWidth: widgetArea.basedOnSize('100%', 'calc(100% - 320px)'),
  paddingRight: theme.spacing(widgetArea.basedOnSize(0, 6, 8)),
  paddingBottom: theme.spacing(widgetArea.basedOnSize(4, 0)),
}));

export const Aside = styled('div')(({ theme, widgetArea }) => ({
  width: widgetArea.basedOnSize(undefined, '45%', '34%'),
  minWidth: 320,
  position: 'sticky',
  bottom: 0,
  padding: theme.spacing(widgetArea.basedOnSize(4, 0)),
  margin: theme.spacing(widgetArea.basedOnSize(-4, 0)),
  paddingTop: 0,
  marginTop: 0,
  zIndex: 1,
}));
