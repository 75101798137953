import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import { ProductListItemSkeleton } from '@guest-widgets/shared/src/components/ProductListItem/ProductListItemSkeleton';
import { styled } from '@guest-widgets/core';

export const CrossSellingSkeleton = () => {
  return (
    <div>
      <Skeleton variant="text" width={200} height={40} />
      <ItemsWrapper>
        <Items>
          <ItemWrapper>
            <ProductListItemSkeleton />
          </ItemWrapper>
          <ItemWrapper>
            <ProductListItemSkeleton />
          </ItemWrapper>
          <ItemWrapper>
            <ProductListItemSkeleton />
          </ItemWrapper>
        </Items>
      </ItemsWrapper>
    </div>
  );
};

const customSpacing = 4;

export const ItemsWrapper = styled('div')(({ theme, widgetArea }) => ({
  margin: `-${theme.spacing(customSpacing)} -${theme.spacing(widgetArea.basedOnSize(4, 6, 8))}`,
  overflowX: 'auto',
  overflowY: 'hidden',
}));

export const Items = styled('div')(({ theme, widgetArea }) => ({
  padding: `${theme.spacing(customSpacing)} ${theme.spacing(widgetArea.basedOnSize(4, 6, 8))}`,
  display: 'flex',
  float: 'left',
}));

export const ItemWrapper = styled('div')(({ theme: { spacing }, widgetArea }) => ({
  '&:not(:last-child)': {
    marginRight: spacing(widgetArea.basedOnSize(3, 4, 5)),
  },
  width: spacing(widgetArea.basedOnSize(69, 69, 84)),
  display: 'inline-block',
}));
