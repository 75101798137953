import type { ListProductDto } from '@checkfront/guest-experience-api-api-client-javascript-axios';
import { GuestProduct } from '@guest-widgets/booking/src/App/contexts/productContext/product';
import {
  Settings,
  SupportedCurrencies,
} from '@guest-widgets/booking/src/App/contexts/settingsContext/settings';
import type { CartItem } from '@guest-widgets/booking/src/App/contexts/apiContext/cart/cart';
import type { CartTotals } from '@guest-widgets/booking/src/App/contexts/cartContext/cart';

interface IsAvailable {
  isAvailable: () => boolean;
}

export interface VirtualPageProps {
  title: string;
  path: string;
}

export interface VirtualPageViewData {
  title: string;
  path: string;
}

export interface SearchProductsData {
  keyword: string;
}

export interface ViewProductData {
  product: GuestProduct;
  settings: Settings;
}

export interface ViewCartData {
  items: CartItem[];
  currency: SupportedCurrencies;
}

export interface UpdateCartData {
  item: CartItem;
  currency: SupportedCurrencies;
  totalAmount?: number;
}

export interface ProductsData {
  products: ListProductDto[];
  listId: string;
}
export interface PageViewData {
  title?: string;
  virtualPageViewData: VirtualPageViewData;
}

export interface BeginCheckoutData {
  currency: SupportedCurrencies;
  items: CartItem[];
  totals?: CartTotals;
}

export interface PurchaseData {
  bookingNumber: string;
  items: CartItem[];
  currency: SupportedCurrencies;
  totals?: CartTotals;
}

export interface TrackingEvents {
  pageView: (pageViewData: PageViewData) => void;
  searchProducts: (keyword: string) => void;
  viewProductList: (viewProductListData: ProductsData) => void;
  viewProduct: (productData: ViewProductData) => void;
  selectProduct: (selectProductData: ProductsData) => void;
  viewCart: (cartData: ViewCartData) => void;
  addToCart: (addToCartData: UpdateCartData) => void;
  removeFromCart: (removeCartData: UpdateCartData) => void;
  checkout: (checkoutData: BeginCheckoutData) => void;
  purchase: (purchaseData: PurchaseData) => void;
}

export type Tag = Partial<TrackingEvents> & IsAvailable;

export const customerTrackingCreator = (tags: Tag[]): Partial<TrackingEvents> => ({
  pageView: (...args) => tags.forEach((s) => s.pageView?.(...args)),
  searchProducts: (...args) => tags.forEach((s) => s.searchProducts?.(...args)),
  viewProductList: (...args) => tags.forEach((s) => s.viewProductList?.(...args)),
  viewProduct: (...args) => tags.forEach((s) => s.viewProduct?.(...args)),
  selectProduct: (...args) => tags.forEach((s) => s.selectProduct?.(...args)),
  viewCart: (...args) => tags.forEach((s) => s.viewCart?.(...args)),
  addToCart: (...args) => tags.forEach((s) => s.addToCart?.(...args)),
  removeFromCart: (...args) => tags.forEach((s) => s.removeFromCart?.(...args)),
  checkout: (...args) => tags.forEach((s) => s.checkout?.(...args)),
  purchase: (...args) => tags.forEach((s) => s.purchase?.(...args)),
});
