import React, { createContext, PropsWithChildren, useContext, useEffect } from 'react';
import type { DetailProductDto } from '@checkfront/guest-experience-api-api-client-javascript-axios/dist/api';
import { Loadable, useLoadable } from '@guest-widgets/shared/src/utils/loadable/loadable';
import { giftCardProductApi, productApi } from '@guest-widgets/shared/src/apis/guestExperience';
import { useGetGiftCardParamId } from '@guest-widgets/shared/src/hooks/useGetGiftCardParamId';
import { DetailGiftCardProductDto } from '@checkfront/guest-experience-api-api-client-javascript-axios/api';

import { useSettings } from './SettingsContext/settingsContext';

export interface ProductContext {
  product: Loadable<DetailProductDto | DetailGiftCardProductDto>;
}

export const productContext = createContext<ProductContext>({} as ProductContext);

export const ProductProvider = ({ children }: PropsWithChildren<{}>) => {
  const { productId, customerCode, locale } = useSettings();
  const { giftCardId } = useGetGiftCardParamId();
  const getProduct = giftCardId ? giftCardProductApi.getGiftCardDetails : productApi.getProduct;

  const [product, loadProduct] = useLoadable<DetailProductDto | DetailGiftCardProductDto>(
    getProduct
  );

  useEffect(() => {
    const id = giftCardId ?? productId;
    loadProduct(id, customerCode, locale);
  }, [productId, giftCardId, customerCode]);

  return <productContext.Provider value={{ product }}>{children}</productContext.Provider>;
};

export const useProduct = () => useContext(productContext);
