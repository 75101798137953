import React, { lazy, Suspense, useEffect } from 'react';
import { useWidget, styled } from '@guest-widgets/core';
import { classes } from '@guest-widgets/shared/src/classes';
import { Loadable } from '@guest-widgets/shared/src/utils/loadable/loadable';
import { ErrorViews, ErrorState } from '@guest-widgets/shared/src/components/ErrorViews';
import {
  DetailGiftCardProductDto,
  DetailProductDto,
} from '@checkfront/guest-experience-api-api-client-javascript-axios';
import { useQueryRouter } from '@guest-widgets/shared/src/components/Router/QueryRouter';
import { config } from '@guest-widgets/shared/src/config';
import { useApplication } from '@guest-widgets/shared/src/contexts/appplicationContext/applicationContext';
import {
  Container,
  Content,
  Main,
  Aside,
} from '@guest-widgets/shared/src/components/ProductDetailsSkeleton/ProductDetailsSkeleton';
import {
  ProductDetailsSkeleton,
  TripAdvisorSkeleton,
} from '@guest-widgets/shared/src/components/ProductDetailsSkeleton/ProductDetailsSkeleton';
import { CrossSellingSkeleton } from '@guest-widgets/shared/src/components/ProductDetailsSkeleton/CrossSellingSkeleton';
import { ReviewsSkeleton } from '@guest-widgets/shared/src/components/ProductDetailsSkeleton/ReviewsSkeleton';

import { useProduct } from '../productContext';
import { useSettings } from '../SettingsContext/settingsContext';
import { BookingSkeleton } from '../ProductDetailsSkeleton/BookingSkeleton';

import { GoogleStructuredData } from './GoogleStructuredData';
import { Location } from './Location/Location';
import { ProductHead } from './ProductHead/ProductHead';
import { Information } from './Information/Information';
import { Description } from './Description';

const Booking = lazy(() => import('./Booking'));
const Reviews = lazy(() => import('./Reviews/Reviews'));
const Tripadvisor = lazy(() => import('./Tripadvisor/Tripadvisor'));
const CrossSelling = lazy(() => import('./CrossSelling'));

interface ProductDetailsProps {
  bookingComponent?: React.FC;
}

export const ProductDetails = ({
  bookingComponent: givenBookingComponent,
}: ProductDetailsProps) => {
  const { product } = useProduct();
  const { widgetArea, containerElement } = useWidget();
  const { productId } = useSettings();
  const { get } = useQueryRouter();
  const { productIdQueryKey } = config;
  const { refresh } = useApplication();

  const BookingComponent: React.FC = givenBookingComponent ?? Booking;

  useEffect(() => {
    if (get(productIdQueryKey) === productId)
      containerElement.scrollIntoView({ behavior: 'smooth' });
  }, [product.value]);

  const reviews = (
    <Suspense fallback={<ReviewsSkeleton />}>
      <Reviews />
    </Suspense>
  );

  const crossSelling = (
    <Suspense fallback={<CrossSellingSkeleton />}>
      <CrossSelling />
    </Suspense>
  );

  const tripadvisor = (product: Loadable<DetailProductDto | DetailGiftCardProductDto>) => (
    <Suspense fallback={<TripAdvisorSkeleton />}>
      <Tripadvisor data={product.value.operator?.tripadvisor} />
    </Suspense>
  );

  if (product.isLoading || product.isEmpty) return <ProductDetailsSkeleton hasBackButton={true} />;

  if (product.isFailed)
    return (
      <Container className={classes.details.root}>
        <ErrorViews
          type={
            product.error.response?.status === 404 ? ErrorState.NotFound : ErrorState.UnableToLoad
          }
          onClick={() => refresh()}
        />
      </Container>
    );

  return (
    <Container className={classes.details.root}>
      <GoogleStructuredData />

      <Content className={classes.details.content.root}>
        <Main className={classes.details.content.main}>
          <ProductHead />
          <Description />
          <Information />
          <Location />

          {!widgetArea.isMedium && reviews}
          {widgetArea.isSmall && (
            <>
              {tripadvisor(product)}

              {crossSelling}
            </>
          )}
        </Main>
        <Aside className={classes.details.content.side}>
          <Suspense fallback={<BookingSkeleton />}>
            <BookingComponent />
          </Suspense>
          {!widgetArea.isSmall && tripadvisor(product)}
        </Aside>
      </Content>
      {widgetArea.isMedium && reviews}
      {!widgetArea.isSmall && crossSelling}
    </Container>
  );
};
